<template>
  <v-dialog persistent @keydown.esc="close" v-model="bDialogAddAdministrator" :width="screenWidth + '%'">
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-add-administrator">
          <p class="title-add-administrator">Creación de administrador</p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-img-text-field">
          <div class="global-content-img">
            <div v-if="dataImg !== null" class="global-content-backgorund">
              <v-btn icon @click="removeImg" class="global-button-delete-item">
                <v-icon size="20px"> mdi-delete </v-icon>
              </v-btn>
              <img class="global-img-src" :src="maskedFile(dataImg)" />
            </div>
            <div v-else class="global-content-backgorund">
              <v-btn id="btnAddImg" @click="$refs.inputUpload.click()" @dragover="dragover" @dragleave="dragleave"
                @drop="drop" class="global-button-add-img">
                <div>
                  <v-icon size="40px"> mdi-image-plus </v-icon>
                  <p class="global-text-img-add">Arrastra ó da clic</p>
                  <p class="global-text-img-add">para añadir</p>
                </div>
              </v-btn>
            </div>
            <input ref="inputUpload" style="display: none" type="file" size="60" accept=".jpg, .jpeg, .png"
              multiple="false" @change="uploadImg" />
          </div>
          <v-row class="content-text-field">
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-text-field v-model="sName" label="Nombre" placeholder="Nombre..." class="global-text-field"
                color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                persistent-placeholder outlined maxlength="50" @keyup="validateForm()">
                <template slot="label">
                  <span>Nombre<span class="important-data">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-text-field v-model="sLastname" label="Apellido" placeholder="Apellido..." class="global-text-field"
                color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                persistent-placeholder outlined maxlength="50" @keyup="validateForm()">
                <template slot="label">
                  <span>Apellido<span class="important-data">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-text-field v-model="sRole" label="Ocupación laboral" placeholder="Ocupación laboral..."
                class="global-text-field" color="var(--primary-color-border-input)"
                background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="40"
                @keyup="validateForm()">
                <template slot="label">
                  <span>Ocupación laboral<span class="important-data">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-text-field v-model="sEmail" label="Correo electrónico" placeholder="Correo electrónico..."
                class="global-text-field" color="var(--primary-color-border-input)"
                background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="70"
                @keyup="validateForm()">
                <template slot="label">
                  <span>Correo electrónico<span class="important-data">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8">
              <phone-component-global :bImportantDate="true" @updateCountryCallingCode="updateCountryCallingCode"
                :sCountryCallingCodeOrigin="sCountryCallingCode" @updateAreaCallingCode="updateAreaCallingCode"
                :sAreaCallingCodeOrigin="sAreaCallingCode" @updatePhoneNumber="updatePhoneNumber"
                :sPhoneNumberOrigin="sPhoneNumber" @updatePhoneExtension="updatePhoneExtension"
                :sPhoneExtensionOrigin="sPhoneExtension" :bClearTextFieldsPhone="bClearTextFieldsPhone" />
            </v-col>
          </v-row>
        </div>

        <v-divider class="divider-global mt-9"></v-divider>

        <div class="content-table-permission">
          <p class="text-title-permissions">Permisos administrativos</p>

          <v-data-table v-if="!isMobile" mobile-breakpoint="0" :headers="headers" :disable-sort="true"
            :items="aPermission" :hide-default-footer="true" no-data-text="No hay información disponible para mostrar."
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              'items-per-page-all-text': 'Todos',
            }" class="global-table">
            <template v-slot:[`item.bShow`]="{ item }">
              <div class="content-switch">
                <v-switch class="switch-permission" v-model="item.bShow" color="var(--primary-color-text-yellow)"
                  hide-details @change="validateForm(), validateCheckShow(item)"></v-switch>
              </div>
            </template>
            <template v-slot:[`item.bAdmin`]="{ item }">
              <div class="content-switch">
                <v-switch class="switch-permission" v-model="item.bAdmin" color="var(--primary-color-text-yellow)"
                  hide-details @change="validateForm(), validateCheckAdmin(item)"></v-switch>
              </div>
            </template>
          </v-data-table>
          <table-mobile-permissions-component-global v-show="isMobile" :bModify="true" :aTable="aPermission"
            :headers="headers" @changePermission="changePermission()" />
        </div>
      </v-card-text>

      <v-card-text>
        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn elevation="0" :disabled="!bAdd" class="global-btn-primary" :loading="bLoading"
              @click="addAdministrator()">
              Crear admin
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";

export default {
  props: {
    bDialogAddAdministrator: Boolean,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      bAdd: false,
      dataImg: null,

      sName: "",
      sLastname: "",
      sEmail: "",
      sRole: "",
      sCountryCallingCode: "52",
      sAreaCallingCode: "",
      sPhoneNumber: "",
      sPhoneExtension: "",
      tCreatedAt: "",
      aPermission: [],
      headers: [
        {
          text: "Módulos",
          sortable: false,
          value: "sModuleName",
          class: "global-header-table global-header-module",
          cellClass: "global-body-table global-body-module ",
        },
        {
          text: "Ver",
          sortable: false,
          value: "bShow",
          align: "center",

          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Administrar",
          value: "bAdmin",
          sortable: false,

          align: "center",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
      ],
      isMobile: false,
      bClearTextFieldsPhone: false
    };
  },
  beforeMount() { },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 599.99) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 100;
      }
    },
    uploadImg: function (e) {
      const files = e.target.files;
      this.dataImg = files;
      // var btnAddImg = document.getElementById("btnAddImg");
      // btnAddImg.classList.remove("global-button-add-img-required");
      this.validateForm();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.dataImg = event.dataTransfer.files;
      this.validateForm();

      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    removeImg: function () {
      this.dataImg = null;
      if (this.$refs.inputUpload.value) {
        this.$refs.inputUpload.value = "";
      }
      this.$nextTick(() => {
        var btnAddImg = document.getElementById("btnAddImg");
        if (btnAddImg) {
          btnAddImg.classList.add("global-button-add-img-required");
        }
      });
      this.validateForm();
    },
    maskedFile: function (file) {
      return URL.createObjectURL(file[0]);
    },
    close() {
      this.$emit("setDialogAddAdministrator");
      var btnAddImg = document.getElementById("btnAddImg");
      if (btnAddImg) {
        btnAddImg.classList.remove("global-button-add-img-required");
      }
      this.bLoading = false;
      this.$refs.inputUpload.value = "";
      this.dataImg = null;
      this.sName = "";
      this.sLastname = "";
      this.sEmail = "";
      this.sRole = "";
      this.tCreatedAt = "";
      this.bClearTextFieldsPhone = !this.bClearTextFieldsPhone;
      this.sCountryCallingCode = "";
    },
    addAdministrator() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminAdministrator) {
            let module = [];
            for (let index = 0; index < this.aPermission.length; index++) {
              const element = this.aPermission[index];
              module.push({
                sModuleId: element.sModuleId,
                aPermissions: [
                  {
                    sPermissionId: element.sShowPermissionId,
                    bBoolean: element.bShow,
                  },
                  {
                    sPermissionId: element.sAdminPermissionId,
                    bBoolean: element.bAdmin,
                  },
                ],
              });
            }

            const payload = {
              sName: this.sName,
              sLastname: this.sLastname,
              sEmail: this.sEmail,
              sRole: this.sRole,
              sCountryCallingCode: this.sCountryCallingCode,
              sAreaCallingCode: this.sAreaCallingCode,
              sPhoneNumber: this.sPhoneNumber,
              sPhoneExtension: this.sPhoneExtension,
              aUserPermissions: module,
            },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                  contentType:
                    "application/x-www-form-urlencoded;charset=utf-8",
                },
              };
            DB.post(`${URI}/api/sp/v1/users`, payload, config)
              .then((response) => {
                this.addImgAdmin(
                  response.data.results.sUserId,
                  response.data.message,
                  response.data.results.sName +
                  " " +
                  response.data.results.sLastname
                );
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });

            // Success("Acceso correcto");
            this.bLoading = true;
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    addImgAdmin(id, Message, Name) {
      let FullName = Name;
      let form = new FormData();
      form.append("aPicture", this.dataImg[0]);

      const config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };

      DB.patch(`${URI}/api/sp/v1/users/${id}`, form, config)
        .then((response) => {
          this.bLoading = false;
          this.$store.commit("refresher", true);
          this.close();
          this.Success(Message, FullName);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    getModules() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.get(`${URI}/api/sp/v1/permissions/modules`, config)
        .then((response) => {
          // this.aPermission = response.data.results;
          this.aPermission = response.data.results.map((e) => {
            return {
              ...e,
              sModuleId: e.sModuleId,
              sModuleName: e.sModuleName,
              bShow: e.aPermissions[0].bBoolean,
              sShowPermissionId: e.aPermissions[0].sPermissionId,
              bAdmin: e.aPermissions[1].bBoolean,
              sAdminPermissionId: e.aPermissions[1].sPermissionId,
            };
          })
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    fieldNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    validateForm() {

      if (this.dataImg == null) {
        var btnAddImg = document.getElementById("btnAddImg");
        if (btnAddImg !== null) {
          btnAddImg.classList.add("global-button-add-img-required");
        }
      } else {
        var btnAddImg = document.getElementById("btnAddImg");
        if (btnAddImg !== null) {
          btnAddImg.classList.remove("global-button-add-img-required");
        }
      }
      this.bAdd =
        this.dataImg !== null &&
        this.sName.trim() !== "" &&
        this.sLastname.trim() !== "" &&
        this.sEmail.trim() !== "" &&
        this.sRole.trim() !== "" &&
        this.sCountryCallingCode.trim() !== "" &&
        this.sAreaCallingCode.trim() !== "" &&
        this.sPhoneNumber.trim() !== "" &&
        this.aPermission.filter((e) => e.bShow !== false || e.bAdmin !== false)
          .length > 0;
    },
    changePermission() {
      this.validateForm();
    },

    //UPDATE DATE PHONE
    updateCountryCallingCode(val) {
      this.sCountryCallingCode = val;
      this.validateForm();
    },
    updateAreaCallingCode(val) {
      this.sAreaCallingCode = val;
      this.validateForm();
    },
    updatePhoneNumber(val) {
      this.sPhoneNumber = val;
      this.validateForm();
    },
    updatePhoneExtension(val) {
      this.sPhoneExtension = val;
      this.validateForm();
    },
    validateCheckAdmin(item) {
      if (item.bAdmin == true) {
        item.bShow = true;
        this.validateForm();
      }
    },
    validateCheckShow(item) {
      if (item.bShow == false) {
        item.bAdmin = false;
        this.validateForm();

      }
    },
  },
  computed: {
    // bDialogAddAdministrator() {
    //   return this.$store.state.bDialogAddAdministrator;
    // },
    bShowAdministrator() {
      return this.$store.state.bShowAdministrator;
    },
    bAdminAdministrator() {
      return this.$store.state.bAdminAdministrator;
    },
  },
  watch: {
    bDialogAddAdministrator() {
      if (this.bDialogAddAdministrator) {
        this.getModules();
        this.tCreatedAt = moment(new Date())
          .format("DD/MM/YYYY")
          .replaceAll("/", "-");
      }
    },
  },
};
</script>
<style scoped>
.content-title-add-administrator {
  width: 100%;
}

.title-add-administrator {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
  word-break: normal;
}

.content-img-text-field {
  display: flex;
}

.text-title-permissions {
  text-align: start;
  letter-spacing: 4.8px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  /* margin-right: 10px; */
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}

.content-text-field {
  margin-bottom: 10px;
  margin-top: 5px;
}

.content-switch {
  display: flex;
  justify-content: center;
  width: 100%;
}

.switch-permission {
  margin: 0px;
  padding: 0px;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}

.content-btn-primary {
  width: 20%;
}

.content-btn-second {
  width: 20%;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-img {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-right: 15px;
    justify-content: center;
    display: flex;
  }

  .content-img-text-field {
    display: block;
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-primary {
    width: 100%;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>